      .fa-rotate-1{-ms-transform:rotate(1deg); -webkit-transform:rotate(1deg); transform:rotate(1deg);}
      .fa-rotate-2{-ms-transform:rotate(2deg); -webkit-transform:rotate(2deg); transform:rotate(2deg);}
      .fa-rotate-3{-ms-transform:rotate(3deg); -webkit-transform:rotate(3deg); transform:rotate(3deg);}
      .fa-rotate-4{-ms-transform:rotate(4deg); -webkit-transform:rotate(4deg); transform:rotate(4deg);}
      .fa-rotate-5{-ms-transform:rotate(5deg); -webkit-transform:rotate(5deg); transform:rotate(5deg);}
      .fa-rotate-6{-ms-transform:rotate(6deg); -webkit-transform:rotate(6deg); transform:rotate(6deg);}
      .fa-rotate-7{-ms-transform:rotate(7deg); -webkit-transform:rotate(7deg); transform:rotate(7deg);}
      .fa-rotate-8{-ms-transform:rotate(8deg); -webkit-transform:rotate(8deg); transform:rotate(8deg);}
      .fa-rotate-9{-ms-transform:rotate(9deg); -webkit-transform:rotate(9deg); transform:rotate(9deg);}
      .fa-rotate-10{-ms-transform:rotate(10deg); -webkit-transform:rotate(10deg); transform:rotate(10deg);}
      .fa-rotate-11{-ms-transform:rotate(11deg); -webkit-transform:rotate(11deg); transform:rotate(11deg);}
      .fa-rotate-12{-ms-transform:rotate(12deg); -webkit-transform:rotate(12deg); transform:rotate(12deg);}
      .fa-rotate-13{-ms-transform:rotate(13deg); -webkit-transform:rotate(13deg); transform:rotate(13deg);}
      .fa-rotate-14{-ms-transform:rotate(14deg); -webkit-transform:rotate(14deg); transform:rotate(14deg);}
      .fa-rotate-15{-ms-transform:rotate(15deg); -webkit-transform:rotate(15deg); transform:rotate(15deg);}
      .fa-rotate-16{-ms-transform:rotate(16deg); -webkit-transform:rotate(16deg); transform:rotate(16deg);}
      .fa-rotate-17{-ms-transform:rotate(17deg); -webkit-transform:rotate(17deg); transform:rotate(17deg);}
      .fa-rotate-18{-ms-transform:rotate(18deg); -webkit-transform:rotate(18deg); transform:rotate(18deg);}
      .fa-rotate-19{-ms-transform:rotate(19deg); -webkit-transform:rotate(19deg); transform:rotate(19deg);}
      .fa-rotate-20{-ms-transform:rotate(20deg); -webkit-transform:rotate(20deg); transform:rotate(20deg);}
      .fa-rotate-21{-ms-transform:rotate(21deg); -webkit-transform:rotate(21deg); transform:rotate(21deg);}
      .fa-rotate-22{-ms-transform:rotate(22deg); -webkit-transform:rotate(22deg); transform:rotate(22deg);}
      .fa-rotate-23{-ms-transform:rotate(23deg); -webkit-transform:rotate(23deg); transform:rotate(23deg);}
      .fa-rotate-24{-ms-transform:rotate(24deg); -webkit-transform:rotate(24deg); transform:rotate(24deg);}
      .fa-rotate-25{-ms-transform:rotate(25deg); -webkit-transform:rotate(25deg); transform:rotate(25deg);}
      .fa-rotate-26{-ms-transform:rotate(26deg); -webkit-transform:rotate(26deg); transform:rotate(26deg);}
      .fa-rotate-27{-ms-transform:rotate(27deg); -webkit-transform:rotate(27deg); transform:rotate(27deg);}
      .fa-rotate-28{-ms-transform:rotate(28deg); -webkit-transform:rotate(28deg); transform:rotate(28deg);}
      .fa-rotate-29{-ms-transform:rotate(29deg); -webkit-transform:rotate(29deg); transform:rotate(29deg);}
      .fa-rotate-30{-ms-transform:rotate(30deg); -webkit-transform:rotate(30deg); transform:rotate(30deg);}
      .fa-rotate-31{-ms-transform:rotate(31deg); -webkit-transform:rotate(31deg); transform:rotate(31deg);}
      .fa-rotate-32{-ms-transform:rotate(32deg); -webkit-transform:rotate(32deg); transform:rotate(32deg);}
      .fa-rotate-33{-ms-transform:rotate(33deg); -webkit-transform:rotate(33deg); transform:rotate(33deg);}
      .fa-rotate-34{-ms-transform:rotate(34deg); -webkit-transform:rotate(34deg); transform:rotate(34deg);}
      .fa-rotate-35{-ms-transform:rotate(35deg); -webkit-transform:rotate(35deg); transform:rotate(35deg);}
      .fa-rotate-36{-ms-transform:rotate(36deg); -webkit-transform:rotate(36deg); transform:rotate(36deg);}
      .fa-rotate-37{-ms-transform:rotate(37deg); -webkit-transform:rotate(37deg); transform:rotate(37deg);}
      .fa-rotate-38{-ms-transform:rotate(38deg); -webkit-transform:rotate(38deg); transform:rotate(38deg);}
      .fa-rotate-39{-ms-transform:rotate(39deg); -webkit-transform:rotate(39deg); transform:rotate(39deg);}
      .fa-rotate-40{-ms-transform:rotate(40deg); -webkit-transform:rotate(40deg); transform:rotate(40deg);}
      .fa-rotate-41{-ms-transform:rotate(41deg); -webkit-transform:rotate(41deg); transform:rotate(41deg);}
      .fa-rotate-42{-ms-transform:rotate(42deg); -webkit-transform:rotate(42deg); transform:rotate(42deg);}
      .fa-rotate-43{-ms-transform:rotate(43deg); -webkit-transform:rotate(43deg); transform:rotate(43deg);}
      .fa-rotate-44{-ms-transform:rotate(44deg); -webkit-transform:rotate(44deg); transform:rotate(44deg);}
      .fa-rotate-45{-ms-transform:rotate(45deg); -webkit-transform:rotate(45deg); transform:rotate(45deg);}
      .fa-rotate-46{-ms-transform:rotate(46deg); -webkit-transform:rotate(46deg); transform:rotate(46deg);}
      .fa-rotate-47{-ms-transform:rotate(47deg); -webkit-transform:rotate(47deg); transform:rotate(47deg);}
      .fa-rotate-48{-ms-transform:rotate(48deg); -webkit-transform:rotate(48deg); transform:rotate(48deg);}
      .fa-rotate-49{-ms-transform:rotate(49deg); -webkit-transform:rotate(49deg); transform:rotate(49deg);}
      .fa-rotate-50{-ms-transform:rotate(50deg); -webkit-transform:rotate(50deg); transform:rotate(50deg);}
      .fa-rotate-51{-ms-transform:rotate(51deg); -webkit-transform:rotate(51deg); transform:rotate(51deg);}
      .fa-rotate-52{-ms-transform:rotate(52deg); -webkit-transform:rotate(52deg); transform:rotate(52deg);}
      .fa-rotate-53{-ms-transform:rotate(53deg); -webkit-transform:rotate(53deg); transform:rotate(53deg);}
      .fa-rotate-54{-ms-transform:rotate(54deg); -webkit-transform:rotate(54deg); transform:rotate(54deg);}
      .fa-rotate-55{-ms-transform:rotate(55deg); -webkit-transform:rotate(55deg); transform:rotate(55deg);}
      .fa-rotate-56{-ms-transform:rotate(56deg); -webkit-transform:rotate(56deg); transform:rotate(56deg);}
      .fa-rotate-57{-ms-transform:rotate(57deg); -webkit-transform:rotate(57deg); transform:rotate(57deg);}
      .fa-rotate-58{-ms-transform:rotate(58deg); -webkit-transform:rotate(58deg); transform:rotate(58deg);}
      .fa-rotate-59{-ms-transform:rotate(59deg); -webkit-transform:rotate(59deg); transform:rotate(59deg);}
      .fa-rotate-60{-ms-transform:rotate(60deg); -webkit-transform:rotate(60deg); transform:rotate(60deg);}
      .fa-rotate-61{-ms-transform:rotate(61deg); -webkit-transform:rotate(61deg); transform:rotate(61deg);}
      .fa-rotate-62{-ms-transform:rotate(62deg); -webkit-transform:rotate(62deg); transform:rotate(62deg);}
      .fa-rotate-63{-ms-transform:rotate(63deg); -webkit-transform:rotate(63deg); transform:rotate(63deg);}
      .fa-rotate-64{-ms-transform:rotate(64deg); -webkit-transform:rotate(64deg); transform:rotate(64deg);}
      .fa-rotate-65{-ms-transform:rotate(65deg); -webkit-transform:rotate(65deg); transform:rotate(65deg);}
      .fa-rotate-66{-ms-transform:rotate(66deg); -webkit-transform:rotate(66deg); transform:rotate(66deg);}
      .fa-rotate-67{-ms-transform:rotate(67deg); -webkit-transform:rotate(67deg); transform:rotate(67deg);}
      .fa-rotate-68{-ms-transform:rotate(68deg); -webkit-transform:rotate(68deg); transform:rotate(68deg);}
      .fa-rotate-69{-ms-transform:rotate(69deg); -webkit-transform:rotate(69deg); transform:rotate(69deg);}
      .fa-rotate-70{-ms-transform:rotate(70deg); -webkit-transform:rotate(70deg); transform:rotate(70deg);}
      .fa-rotate-71{-ms-transform:rotate(71deg); -webkit-transform:rotate(71deg); transform:rotate(71deg);}
      .fa-rotate-72{-ms-transform:rotate(72deg); -webkit-transform:rotate(72deg); transform:rotate(72deg);}
      .fa-rotate-73{-ms-transform:rotate(73deg); -webkit-transform:rotate(73deg); transform:rotate(73deg);}
      .fa-rotate-74{-ms-transform:rotate(74deg); -webkit-transform:rotate(74deg); transform:rotate(74deg);}
      .fa-rotate-75{-ms-transform:rotate(75deg); -webkit-transform:rotate(75deg); transform:rotate(75deg);}
      .fa-rotate-76{-ms-transform:rotate(76deg); -webkit-transform:rotate(76deg); transform:rotate(76deg);}
      .fa-rotate-77{-ms-transform:rotate(77deg); -webkit-transform:rotate(77deg); transform:rotate(77deg);}
      .fa-rotate-78{-ms-transform:rotate(78deg); -webkit-transform:rotate(78deg); transform:rotate(78deg);}
      .fa-rotate-79{-ms-transform:rotate(79deg); -webkit-transform:rotate(79deg); transform:rotate(79deg);}
      .fa-rotate-80{-ms-transform:rotate(80deg); -webkit-transform:rotate(80deg); transform:rotate(80deg);}
      .fa-rotate-81{-ms-transform:rotate(81deg); -webkit-transform:rotate(81deg); transform:rotate(81deg);}
      .fa-rotate-82{-ms-transform:rotate(82deg); -webkit-transform:rotate(82deg); transform:rotate(82deg);}
      .fa-rotate-83{-ms-transform:rotate(83deg); -webkit-transform:rotate(83deg); transform:rotate(83deg);}
      .fa-rotate-84{-ms-transform:rotate(84deg); -webkit-transform:rotate(84deg); transform:rotate(84deg);}
      .fa-rotate-85{-ms-transform:rotate(85deg); -webkit-transform:rotate(85deg); transform:rotate(85deg);}
      .fa-rotate-86{-ms-transform:rotate(86deg); -webkit-transform:rotate(86deg); transform:rotate(86deg);}
      .fa-rotate-87{-ms-transform:rotate(87deg); -webkit-transform:rotate(87deg); transform:rotate(87deg);}
      .fa-rotate-88{-ms-transform:rotate(88deg); -webkit-transform:rotate(88deg); transform:rotate(88deg);}
      .fa-rotate-89{-ms-transform:rotate(89deg); -webkit-transform:rotate(89deg); transform:rotate(89deg);}
      .fa-rotate-90{-ms-transform:rotate(90deg); -webkit-transform:rotate(90deg); transform:rotate(90deg);}
      .fa-rotate-91{-ms-transform:rotate(91deg); -webkit-transform:rotate(91deg); transform:rotate(91deg);}
      .fa-rotate-92{-ms-transform:rotate(92deg); -webkit-transform:rotate(92deg); transform:rotate(92deg);}
      .fa-rotate-93{-ms-transform:rotate(93deg); -webkit-transform:rotate(93deg); transform:rotate(93deg);}
      .fa-rotate-94{-ms-transform:rotate(94deg); -webkit-transform:rotate(94deg); transform:rotate(94deg);}
      .fa-rotate-95{-ms-transform:rotate(95deg); -webkit-transform:rotate(95deg); transform:rotate(95deg);}
      .fa-rotate-96{-ms-transform:rotate(96deg); -webkit-transform:rotate(96deg); transform:rotate(96deg);}
      .fa-rotate-97{-ms-transform:rotate(97deg); -webkit-transform:rotate(97deg); transform:rotate(97deg);}
      .fa-rotate-98{-ms-transform:rotate(98deg); -webkit-transform:rotate(98deg); transform:rotate(98deg);}
      .fa-rotate-99{-ms-transform:rotate(99deg); -webkit-transform:rotate(99deg); transform:rotate(99deg);}
      .fa-rotate-100{-ms-transform:rotate(100deg); -webkit-transform:rotate(100deg); transform:rotate(100deg);}
      .fa-rotate-101{-ms-transform:rotate(101deg); -webkit-transform:rotate(101deg); transform:rotate(101deg);}
      .fa-rotate-102{-ms-transform:rotate(102deg); -webkit-transform:rotate(102deg); transform:rotate(102deg);}
      .fa-rotate-103{-ms-transform:rotate(103deg); -webkit-transform:rotate(103deg); transform:rotate(103deg);}
      .fa-rotate-104{-ms-transform:rotate(104deg); -webkit-transform:rotate(104deg); transform:rotate(104deg);}
      .fa-rotate-105{-ms-transform:rotate(105deg); -webkit-transform:rotate(105deg); transform:rotate(105deg);}
      .fa-rotate-106{-ms-transform:rotate(106deg); -webkit-transform:rotate(106deg); transform:rotate(106deg);}
      .fa-rotate-107{-ms-transform:rotate(107deg); -webkit-transform:rotate(107deg); transform:rotate(107deg);}
      .fa-rotate-108{-ms-transform:rotate(108deg); -webkit-transform:rotate(108deg); transform:rotate(108deg);}
      .fa-rotate-109{-ms-transform:rotate(109deg); -webkit-transform:rotate(109deg); transform:rotate(109deg);}
      .fa-rotate-110{-ms-transform:rotate(110deg); -webkit-transform:rotate(110deg); transform:rotate(110deg);}
      .fa-rotate-111{-ms-transform:rotate(111deg); -webkit-transform:rotate(111deg); transform:rotate(111deg);}
      .fa-rotate-112{-ms-transform:rotate(112deg); -webkit-transform:rotate(112deg); transform:rotate(112deg);}
      .fa-rotate-113{-ms-transform:rotate(113deg); -webkit-transform:rotate(113deg); transform:rotate(113deg);}
      .fa-rotate-114{-ms-transform:rotate(114deg); -webkit-transform:rotate(114deg); transform:rotate(114deg);}
      .fa-rotate-115{-ms-transform:rotate(115deg); -webkit-transform:rotate(115deg); transform:rotate(115deg);}
      .fa-rotate-116{-ms-transform:rotate(116deg); -webkit-transform:rotate(116deg); transform:rotate(116deg);}
      .fa-rotate-117{-ms-transform:rotate(117deg); -webkit-transform:rotate(117deg); transform:rotate(117deg);}
      .fa-rotate-118{-ms-transform:rotate(118deg); -webkit-transform:rotate(118deg); transform:rotate(118deg);}
      .fa-rotate-119{-ms-transform:rotate(119deg); -webkit-transform:rotate(119deg); transform:rotate(119deg);}
      .fa-rotate-120{-ms-transform:rotate(120deg); -webkit-transform:rotate(120deg); transform:rotate(120deg);}
      .fa-rotate-121{-ms-transform:rotate(121deg); -webkit-transform:rotate(121deg); transform:rotate(121deg);}
      .fa-rotate-122{-ms-transform:rotate(122deg); -webkit-transform:rotate(122deg); transform:rotate(122deg);}
      .fa-rotate-123{-ms-transform:rotate(123deg); -webkit-transform:rotate(123deg); transform:rotate(123deg);}
      .fa-rotate-124{-ms-transform:rotate(124deg); -webkit-transform:rotate(124deg); transform:rotate(124deg);}
      .fa-rotate-125{-ms-transform:rotate(125deg); -webkit-transform:rotate(125deg); transform:rotate(125deg);}
      .fa-rotate-126{-ms-transform:rotate(126deg); -webkit-transform:rotate(126deg); transform:rotate(126deg);}
      .fa-rotate-127{-ms-transform:rotate(127deg); -webkit-transform:rotate(127deg); transform:rotate(127deg);}
      .fa-rotate-128{-ms-transform:rotate(128deg); -webkit-transform:rotate(128deg); transform:rotate(128deg);}
      .fa-rotate-129{-ms-transform:rotate(129deg); -webkit-transform:rotate(129deg); transform:rotate(129deg);}
      .fa-rotate-130{-ms-transform:rotate(130deg); -webkit-transform:rotate(130deg); transform:rotate(130deg);}
      .fa-rotate-131{-ms-transform:rotate(131deg); -webkit-transform:rotate(131deg); transform:rotate(131deg);}
      .fa-rotate-132{-ms-transform:rotate(132deg); -webkit-transform:rotate(132deg); transform:rotate(132deg);}
      .fa-rotate-133{-ms-transform:rotate(133deg); -webkit-transform:rotate(133deg); transform:rotate(133deg);}
      .fa-rotate-134{-ms-transform:rotate(134deg); -webkit-transform:rotate(134deg); transform:rotate(134deg);}
      .fa-rotate-135{-ms-transform:rotate(135deg); -webkit-transform:rotate(135deg); transform:rotate(135deg);}
      .fa-rotate-136{-ms-transform:rotate(136deg); -webkit-transform:rotate(136deg); transform:rotate(136deg);}
      .fa-rotate-137{-ms-transform:rotate(137deg); -webkit-transform:rotate(137deg); transform:rotate(137deg);}
      .fa-rotate-138{-ms-transform:rotate(138deg); -webkit-transform:rotate(138deg); transform:rotate(138deg);}
      .fa-rotate-139{-ms-transform:rotate(139deg); -webkit-transform:rotate(139deg); transform:rotate(139deg);}
      .fa-rotate-140{-ms-transform:rotate(140deg); -webkit-transform:rotate(140deg); transform:rotate(140deg);}
      .fa-rotate-141{-ms-transform:rotate(141deg); -webkit-transform:rotate(141deg); transform:rotate(141deg);}
      .fa-rotate-142{-ms-transform:rotate(142deg); -webkit-transform:rotate(142deg); transform:rotate(142deg);}
      .fa-rotate-143{-ms-transform:rotate(143deg); -webkit-transform:rotate(143deg); transform:rotate(143deg);}
      .fa-rotate-144{-ms-transform:rotate(144deg); -webkit-transform:rotate(144deg); transform:rotate(144deg);}
      .fa-rotate-145{-ms-transform:rotate(145deg); -webkit-transform:rotate(145deg); transform:rotate(145deg);}
      .fa-rotate-146{-ms-transform:rotate(146deg); -webkit-transform:rotate(146deg); transform:rotate(146deg);}
      .fa-rotate-147{-ms-transform:rotate(147deg); -webkit-transform:rotate(147deg); transform:rotate(147deg);}
      .fa-rotate-148{-ms-transform:rotate(148deg); -webkit-transform:rotate(148deg); transform:rotate(148deg);}
      .fa-rotate-149{-ms-transform:rotate(149deg); -webkit-transform:rotate(149deg); transform:rotate(149deg);}
      .fa-rotate-150{-ms-transform:rotate(150deg); -webkit-transform:rotate(150deg); transform:rotate(150deg);}
      .fa-rotate-151{-ms-transform:rotate(151deg); -webkit-transform:rotate(151deg); transform:rotate(151deg);}
      .fa-rotate-152{-ms-transform:rotate(152deg); -webkit-transform:rotate(152deg); transform:rotate(152deg);}
      .fa-rotate-153{-ms-transform:rotate(153deg); -webkit-transform:rotate(153deg); transform:rotate(153deg);}
      .fa-rotate-154{-ms-transform:rotate(154deg); -webkit-transform:rotate(154deg); transform:rotate(154deg);}
      .fa-rotate-155{-ms-transform:rotate(155deg); -webkit-transform:rotate(155deg); transform:rotate(155deg);}
      .fa-rotate-156{-ms-transform:rotate(156deg); -webkit-transform:rotate(156deg); transform:rotate(156deg);}
      .fa-rotate-157{-ms-transform:rotate(157deg); -webkit-transform:rotate(157deg); transform:rotate(157deg);}
      .fa-rotate-158{-ms-transform:rotate(158deg); -webkit-transform:rotate(158deg); transform:rotate(158deg);}
      .fa-rotate-159{-ms-transform:rotate(159deg); -webkit-transform:rotate(159deg); transform:rotate(159deg);}
      .fa-rotate-160{-ms-transform:rotate(160deg); -webkit-transform:rotate(160deg); transform:rotate(160deg);}
      .fa-rotate-161{-ms-transform:rotate(161deg); -webkit-transform:rotate(161deg); transform:rotate(161deg);}
      .fa-rotate-162{-ms-transform:rotate(162deg); -webkit-transform:rotate(162deg); transform:rotate(162deg);}
      .fa-rotate-163{-ms-transform:rotate(163deg); -webkit-transform:rotate(163deg); transform:rotate(163deg);}
      .fa-rotate-164{-ms-transform:rotate(164deg); -webkit-transform:rotate(164deg); transform:rotate(164deg);}
      .fa-rotate-165{-ms-transform:rotate(165deg); -webkit-transform:rotate(165deg); transform:rotate(165deg);}
      .fa-rotate-166{-ms-transform:rotate(166deg); -webkit-transform:rotate(166deg); transform:rotate(166deg);}
      .fa-rotate-167{-ms-transform:rotate(167deg); -webkit-transform:rotate(167deg); transform:rotate(167deg);}
      .fa-rotate-168{-ms-transform:rotate(168deg); -webkit-transform:rotate(168deg); transform:rotate(168deg);}
      .fa-rotate-169{-ms-transform:rotate(169deg); -webkit-transform:rotate(169deg); transform:rotate(169deg);}
      .fa-rotate-170{-ms-transform:rotate(170deg); -webkit-transform:rotate(170deg); transform:rotate(170deg);}
      .fa-rotate-171{-ms-transform:rotate(171deg); -webkit-transform:rotate(171deg); transform:rotate(171deg);}
      .fa-rotate-172{-ms-transform:rotate(172deg); -webkit-transform:rotate(172deg); transform:rotate(172deg);}
      .fa-rotate-173{-ms-transform:rotate(173deg); -webkit-transform:rotate(173deg); transform:rotate(173deg);}
      .fa-rotate-174{-ms-transform:rotate(174deg); -webkit-transform:rotate(174deg); transform:rotate(174deg);}
      .fa-rotate-175{-ms-transform:rotate(175deg); -webkit-transform:rotate(175deg); transform:rotate(175deg);}
      .fa-rotate-176{-ms-transform:rotate(176deg); -webkit-transform:rotate(176deg); transform:rotate(176deg);}
      .fa-rotate-177{-ms-transform:rotate(177deg); -webkit-transform:rotate(177deg); transform:rotate(177deg);}
      .fa-rotate-178{-ms-transform:rotate(178deg); -webkit-transform:rotate(178deg); transform:rotate(178deg);}
      .fa-rotate-179{-ms-transform:rotate(179deg); -webkit-transform:rotate(179deg); transform:rotate(179deg);}
      .fa-rotate-180{-ms-transform:rotate(180deg); -webkit-transform:rotate(180deg); transform:rotate(180deg);}
      .fa-rotate-181{-ms-transform:rotate(181deg); -webkit-transform:rotate(181deg); transform:rotate(181deg);}
      .fa-rotate-182{-ms-transform:rotate(182deg); -webkit-transform:rotate(182deg); transform:rotate(182deg);}
      .fa-rotate-183{-ms-transform:rotate(183deg); -webkit-transform:rotate(183deg); transform:rotate(183deg);}
      .fa-rotate-184{-ms-transform:rotate(184deg); -webkit-transform:rotate(184deg); transform:rotate(184deg);}
      .fa-rotate-185{-ms-transform:rotate(185deg); -webkit-transform:rotate(185deg); transform:rotate(185deg);}
      .fa-rotate-186{-ms-transform:rotate(186deg); -webkit-transform:rotate(186deg); transform:rotate(186deg);}
      .fa-rotate-187{-ms-transform:rotate(187deg); -webkit-transform:rotate(187deg); transform:rotate(187deg);}
      .fa-rotate-188{-ms-transform:rotate(188deg); -webkit-transform:rotate(188deg); transform:rotate(188deg);}
      .fa-rotate-189{-ms-transform:rotate(189deg); -webkit-transform:rotate(189deg); transform:rotate(189deg);}
      .fa-rotate-190{-ms-transform:rotate(190deg); -webkit-transform:rotate(190deg); transform:rotate(190deg);}
      .fa-rotate-191{-ms-transform:rotate(191deg); -webkit-transform:rotate(191deg); transform:rotate(191deg);}
      .fa-rotate-192{-ms-transform:rotate(192deg); -webkit-transform:rotate(192deg); transform:rotate(192deg);}
      .fa-rotate-193{-ms-transform:rotate(193deg); -webkit-transform:rotate(193deg); transform:rotate(193deg);}
      .fa-rotate-194{-ms-transform:rotate(194deg); -webkit-transform:rotate(194deg); transform:rotate(194deg);}
      .fa-rotate-195{-ms-transform:rotate(195deg); -webkit-transform:rotate(195deg); transform:rotate(195deg);}
      .fa-rotate-196{-ms-transform:rotate(196deg); -webkit-transform:rotate(196deg); transform:rotate(196deg);}
      .fa-rotate-197{-ms-transform:rotate(197deg); -webkit-transform:rotate(197deg); transform:rotate(197deg);}
      .fa-rotate-198{-ms-transform:rotate(198deg); -webkit-transform:rotate(198deg); transform:rotate(198deg);}
      .fa-rotate-199{-ms-transform:rotate(199deg); -webkit-transform:rotate(199deg); transform:rotate(199deg);}
      .fa-rotate-200{-ms-transform:rotate(200deg); -webkit-transform:rotate(200deg); transform:rotate(200deg);}
      .fa-rotate-201{-ms-transform:rotate(201deg); -webkit-transform:rotate(201deg); transform:rotate(201deg);}
      .fa-rotate-202{-ms-transform:rotate(202deg); -webkit-transform:rotate(202deg); transform:rotate(202deg);}
      .fa-rotate-203{-ms-transform:rotate(203deg); -webkit-transform:rotate(203deg); transform:rotate(203deg);}
      .fa-rotate-204{-ms-transform:rotate(204deg); -webkit-transform:rotate(204deg); transform:rotate(204deg);}
      .fa-rotate-205{-ms-transform:rotate(205deg); -webkit-transform:rotate(205deg); transform:rotate(205deg);}
      .fa-rotate-206{-ms-transform:rotate(206deg); -webkit-transform:rotate(206deg); transform:rotate(206deg);}
      .fa-rotate-207{-ms-transform:rotate(207deg); -webkit-transform:rotate(207deg); transform:rotate(207deg);}
      .fa-rotate-208{-ms-transform:rotate(208deg); -webkit-transform:rotate(208deg); transform:rotate(208deg);}
      .fa-rotate-209{-ms-transform:rotate(209deg); -webkit-transform:rotate(209deg); transform:rotate(209deg);}
      .fa-rotate-210{-ms-transform:rotate(210deg); -webkit-transform:rotate(210deg); transform:rotate(210deg);}
      .fa-rotate-211{-ms-transform:rotate(211deg); -webkit-transform:rotate(211deg); transform:rotate(211deg);}
      .fa-rotate-212{-ms-transform:rotate(212deg); -webkit-transform:rotate(212deg); transform:rotate(212deg);}
      .fa-rotate-213{-ms-transform:rotate(213deg); -webkit-transform:rotate(213deg); transform:rotate(213deg);}
      .fa-rotate-214{-ms-transform:rotate(214deg); -webkit-transform:rotate(214deg); transform:rotate(214deg);}
      .fa-rotate-215{-ms-transform:rotate(215deg); -webkit-transform:rotate(215deg); transform:rotate(215deg);}
      .fa-rotate-216{-ms-transform:rotate(216deg); -webkit-transform:rotate(216deg); transform:rotate(216deg);}
      .fa-rotate-217{-ms-transform:rotate(217deg); -webkit-transform:rotate(217deg); transform:rotate(217deg);}
      .fa-rotate-218{-ms-transform:rotate(218deg); -webkit-transform:rotate(218deg); transform:rotate(218deg);}
      .fa-rotate-219{-ms-transform:rotate(219deg); -webkit-transform:rotate(219deg); transform:rotate(219deg);}
      .fa-rotate-220{-ms-transform:rotate(220deg); -webkit-transform:rotate(220deg); transform:rotate(220deg);}
      .fa-rotate-221{-ms-transform:rotate(221deg); -webkit-transform:rotate(221deg); transform:rotate(221deg);}
      .fa-rotate-222{-ms-transform:rotate(222deg); -webkit-transform:rotate(222deg); transform:rotate(222deg);}
      .fa-rotate-223{-ms-transform:rotate(223deg); -webkit-transform:rotate(223deg); transform:rotate(223deg);}
      .fa-rotate-224{-ms-transform:rotate(224deg); -webkit-transform:rotate(224deg); transform:rotate(224deg);}
      .fa-rotate-225{-ms-transform:rotate(225deg); -webkit-transform:rotate(225deg); transform:rotate(225deg);}
      .fa-rotate-226{-ms-transform:rotate(226deg); -webkit-transform:rotate(226deg); transform:rotate(226deg);}
      .fa-rotate-227{-ms-transform:rotate(227deg); -webkit-transform:rotate(227deg); transform:rotate(227deg);}
      .fa-rotate-228{-ms-transform:rotate(228deg); -webkit-transform:rotate(228deg); transform:rotate(228deg);}
      .fa-rotate-229{-ms-transform:rotate(229deg); -webkit-transform:rotate(229deg); transform:rotate(229deg);}
      .fa-rotate-230{-ms-transform:rotate(230deg); -webkit-transform:rotate(230deg); transform:rotate(230deg);}
      .fa-rotate-231{-ms-transform:rotate(231deg); -webkit-transform:rotate(231deg); transform:rotate(231deg);}
      .fa-rotate-232{-ms-transform:rotate(232deg); -webkit-transform:rotate(232deg); transform:rotate(232deg);}
      .fa-rotate-233{-ms-transform:rotate(233deg); -webkit-transform:rotate(233deg); transform:rotate(233deg);}
      .fa-rotate-234{-ms-transform:rotate(234deg); -webkit-transform:rotate(234deg); transform:rotate(234deg);}
      .fa-rotate-235{-ms-transform:rotate(235deg); -webkit-transform:rotate(235deg); transform:rotate(235deg);}
      .fa-rotate-236{-ms-transform:rotate(236deg); -webkit-transform:rotate(236deg); transform:rotate(236deg);}
      .fa-rotate-237{-ms-transform:rotate(237deg); -webkit-transform:rotate(237deg); transform:rotate(237deg);}
      .fa-rotate-238{-ms-transform:rotate(238deg); -webkit-transform:rotate(238deg); transform:rotate(238deg);}
      .fa-rotate-239{-ms-transform:rotate(239deg); -webkit-transform:rotate(239deg); transform:rotate(239deg);}
      .fa-rotate-240{-ms-transform:rotate(240deg); -webkit-transform:rotate(240deg); transform:rotate(240deg);}
      .fa-rotate-241{-ms-transform:rotate(241deg); -webkit-transform:rotate(241deg); transform:rotate(241deg);}
      .fa-rotate-242{-ms-transform:rotate(242deg); -webkit-transform:rotate(242deg); transform:rotate(242deg);}
      .fa-rotate-243{-ms-transform:rotate(243deg); -webkit-transform:rotate(243deg); transform:rotate(243deg);}
      .fa-rotate-244{-ms-transform:rotate(244deg); -webkit-transform:rotate(244deg); transform:rotate(244deg);}
      .fa-rotate-245{-ms-transform:rotate(245deg); -webkit-transform:rotate(245deg); transform:rotate(245deg);}
      .fa-rotate-246{-ms-transform:rotate(246deg); -webkit-transform:rotate(246deg); transform:rotate(246deg);}
      .fa-rotate-247{-ms-transform:rotate(247deg); -webkit-transform:rotate(247deg); transform:rotate(247deg);}
      .fa-rotate-248{-ms-transform:rotate(248deg); -webkit-transform:rotate(248deg); transform:rotate(248deg);}
      .fa-rotate-249{-ms-transform:rotate(249deg); -webkit-transform:rotate(249deg); transform:rotate(249deg);}
      .fa-rotate-250{-ms-transform:rotate(250deg); -webkit-transform:rotate(250deg); transform:rotate(250deg);}
      .fa-rotate-251{-ms-transform:rotate(251deg); -webkit-transform:rotate(251deg); transform:rotate(251deg);}
      .fa-rotate-252{-ms-transform:rotate(252deg); -webkit-transform:rotate(252deg); transform:rotate(252deg);}
      .fa-rotate-253{-ms-transform:rotate(253deg); -webkit-transform:rotate(253deg); transform:rotate(253deg);}
      .fa-rotate-254{-ms-transform:rotate(254deg); -webkit-transform:rotate(254deg); transform:rotate(254deg);}
      .fa-rotate-255{-ms-transform:rotate(255deg); -webkit-transform:rotate(255deg); transform:rotate(255deg);}
      .fa-rotate-256{-ms-transform:rotate(256deg); -webkit-transform:rotate(256deg); transform:rotate(256deg);}
      .fa-rotate-257{-ms-transform:rotate(257deg); -webkit-transform:rotate(257deg); transform:rotate(257deg);}
      .fa-rotate-258{-ms-transform:rotate(258deg); -webkit-transform:rotate(258deg); transform:rotate(258deg);}
      .fa-rotate-259{-ms-transform:rotate(259deg); -webkit-transform:rotate(259deg); transform:rotate(259deg);}
      .fa-rotate-260{-ms-transform:rotate(260deg); -webkit-transform:rotate(260deg); transform:rotate(260deg);}
      .fa-rotate-261{-ms-transform:rotate(261deg); -webkit-transform:rotate(261deg); transform:rotate(261deg);}
      .fa-rotate-262{-ms-transform:rotate(262deg); -webkit-transform:rotate(262deg); transform:rotate(262deg);}
      .fa-rotate-263{-ms-transform:rotate(263deg); -webkit-transform:rotate(263deg); transform:rotate(263deg);}
      .fa-rotate-264{-ms-transform:rotate(264deg); -webkit-transform:rotate(264deg); transform:rotate(264deg);}
      .fa-rotate-265{-ms-transform:rotate(265deg); -webkit-transform:rotate(265deg); transform:rotate(265deg);}
      .fa-rotate-266{-ms-transform:rotate(266deg); -webkit-transform:rotate(266deg); transform:rotate(266deg);}
      .fa-rotate-267{-ms-transform:rotate(267deg); -webkit-transform:rotate(267deg); transform:rotate(267deg);}
      .fa-rotate-268{-ms-transform:rotate(268deg); -webkit-transform:rotate(268deg); transform:rotate(268deg);}
      .fa-rotate-269{-ms-transform:rotate(269deg); -webkit-transform:rotate(269deg); transform:rotate(269deg);}
      .fa-rotate-270{-ms-transform:rotate(270deg); -webkit-transform:rotate(270deg); transform:rotate(270deg);}
      .fa-rotate-271{-ms-transform:rotate(271deg); -webkit-transform:rotate(271deg); transform:rotate(271deg);}
      .fa-rotate-272{-ms-transform:rotate(272deg); -webkit-transform:rotate(272deg); transform:rotate(272deg);}
      .fa-rotate-273{-ms-transform:rotate(273deg); -webkit-transform:rotate(273deg); transform:rotate(273deg);}
      .fa-rotate-274{-ms-transform:rotate(274deg); -webkit-transform:rotate(274deg); transform:rotate(274deg);}
      .fa-rotate-275{-ms-transform:rotate(275deg); -webkit-transform:rotate(275deg); transform:rotate(275deg);}
      .fa-rotate-276{-ms-transform:rotate(276deg); -webkit-transform:rotate(276deg); transform:rotate(276deg);}
      .fa-rotate-277{-ms-transform:rotate(277deg); -webkit-transform:rotate(277deg); transform:rotate(277deg);}
      .fa-rotate-278{-ms-transform:rotate(278deg); -webkit-transform:rotate(278deg); transform:rotate(278deg);}
      .fa-rotate-279{-ms-transform:rotate(279deg); -webkit-transform:rotate(279deg); transform:rotate(279deg);}
      .fa-rotate-280{-ms-transform:rotate(280deg); -webkit-transform:rotate(280deg); transform:rotate(280deg);}
      .fa-rotate-281{-ms-transform:rotate(281deg); -webkit-transform:rotate(281deg); transform:rotate(281deg);}
      .fa-rotate-282{-ms-transform:rotate(282deg); -webkit-transform:rotate(282deg); transform:rotate(282deg);}
      .fa-rotate-283{-ms-transform:rotate(283deg); -webkit-transform:rotate(283deg); transform:rotate(283deg);}
      .fa-rotate-284{-ms-transform:rotate(284deg); -webkit-transform:rotate(284deg); transform:rotate(284deg);}
      .fa-rotate-285{-ms-transform:rotate(285deg); -webkit-transform:rotate(285deg); transform:rotate(285deg);}
      .fa-rotate-286{-ms-transform:rotate(286deg); -webkit-transform:rotate(286deg); transform:rotate(286deg);}
      .fa-rotate-287{-ms-transform:rotate(287deg); -webkit-transform:rotate(287deg); transform:rotate(287deg);}
      .fa-rotate-288{-ms-transform:rotate(288deg); -webkit-transform:rotate(288deg); transform:rotate(288deg);}
      .fa-rotate-289{-ms-transform:rotate(289deg); -webkit-transform:rotate(289deg); transform:rotate(289deg);}
      .fa-rotate-290{-ms-transform:rotate(290deg); -webkit-transform:rotate(290deg); transform:rotate(290deg);}
      .fa-rotate-291{-ms-transform:rotate(291deg); -webkit-transform:rotate(291deg); transform:rotate(291deg);}
      .fa-rotate-292{-ms-transform:rotate(292deg); -webkit-transform:rotate(292deg); transform:rotate(292deg);}
      .fa-rotate-293{-ms-transform:rotate(293deg); -webkit-transform:rotate(293deg); transform:rotate(293deg);}
      .fa-rotate-294{-ms-transform:rotate(294deg); -webkit-transform:rotate(294deg); transform:rotate(294deg);}
      .fa-rotate-295{-ms-transform:rotate(295deg); -webkit-transform:rotate(295deg); transform:rotate(295deg);}
      .fa-rotate-296{-ms-transform:rotate(296deg); -webkit-transform:rotate(296deg); transform:rotate(296deg);}
      .fa-rotate-297{-ms-transform:rotate(297deg); -webkit-transform:rotate(297deg); transform:rotate(297deg);}
      .fa-rotate-298{-ms-transform:rotate(298deg); -webkit-transform:rotate(298deg); transform:rotate(298deg);}
      .fa-rotate-299{-ms-transform:rotate(299deg); -webkit-transform:rotate(299deg); transform:rotate(299deg);}
      .fa-rotate-300{-ms-transform:rotate(300deg); -webkit-transform:rotate(300deg); transform:rotate(300deg);}
      .fa-rotate-301{-ms-transform:rotate(301deg); -webkit-transform:rotate(301deg); transform:rotate(301deg);}
      .fa-rotate-302{-ms-transform:rotate(302deg); -webkit-transform:rotate(302deg); transform:rotate(302deg);}
      .fa-rotate-303{-ms-transform:rotate(303deg); -webkit-transform:rotate(303deg); transform:rotate(303deg);}
      .fa-rotate-304{-ms-transform:rotate(304deg); -webkit-transform:rotate(304deg); transform:rotate(304deg);}
      .fa-rotate-305{-ms-transform:rotate(305deg); -webkit-transform:rotate(305deg); transform:rotate(305deg);}
      .fa-rotate-306{-ms-transform:rotate(306deg); -webkit-transform:rotate(306deg); transform:rotate(306deg);}
      .fa-rotate-307{-ms-transform:rotate(307deg); -webkit-transform:rotate(307deg); transform:rotate(307deg);}
      .fa-rotate-308{-ms-transform:rotate(308deg); -webkit-transform:rotate(308deg); transform:rotate(308deg);}
      .fa-rotate-309{-ms-transform:rotate(309deg); -webkit-transform:rotate(309deg); transform:rotate(309deg);}
      .fa-rotate-310{-ms-transform:rotate(310deg); -webkit-transform:rotate(310deg); transform:rotate(310deg);}
      .fa-rotate-311{-ms-transform:rotate(311deg); -webkit-transform:rotate(311deg); transform:rotate(311deg);}
      .fa-rotate-312{-ms-transform:rotate(312deg); -webkit-transform:rotate(312deg); transform:rotate(312deg);}
      .fa-rotate-313{-ms-transform:rotate(313deg); -webkit-transform:rotate(313deg); transform:rotate(313deg);}
      .fa-rotate-314{-ms-transform:rotate(314deg); -webkit-transform:rotate(314deg); transform:rotate(314deg);}
      .fa-rotate-315{-ms-transform:rotate(315deg); -webkit-transform:rotate(315deg); transform:rotate(315deg);}
      .fa-rotate-316{-ms-transform:rotate(316deg); -webkit-transform:rotate(316deg); transform:rotate(316deg);}
      .fa-rotate-317{-ms-transform:rotate(317deg); -webkit-transform:rotate(317deg); transform:rotate(317deg);}
      .fa-rotate-318{-ms-transform:rotate(318deg); -webkit-transform:rotate(318deg); transform:rotate(318deg);}
      .fa-rotate-319{-ms-transform:rotate(319deg); -webkit-transform:rotate(319deg); transform:rotate(319deg);}
      .fa-rotate-320{-ms-transform:rotate(320deg); -webkit-transform:rotate(320deg); transform:rotate(320deg);}
      .fa-rotate-321{-ms-transform:rotate(321deg); -webkit-transform:rotate(321deg); transform:rotate(321deg);}
      .fa-rotate-322{-ms-transform:rotate(322deg); -webkit-transform:rotate(322deg); transform:rotate(322deg);}
      .fa-rotate-323{-ms-transform:rotate(323deg); -webkit-transform:rotate(323deg); transform:rotate(323deg);}
      .fa-rotate-324{-ms-transform:rotate(324deg); -webkit-transform:rotate(324deg); transform:rotate(324deg);}
      .fa-rotate-325{-ms-transform:rotate(325deg); -webkit-transform:rotate(325deg); transform:rotate(325deg);}
      .fa-rotate-326{-ms-transform:rotate(326deg); -webkit-transform:rotate(326deg); transform:rotate(326deg);}
      .fa-rotate-327{-ms-transform:rotate(327deg); -webkit-transform:rotate(327deg); transform:rotate(327deg);}
      .fa-rotate-328{-ms-transform:rotate(328deg); -webkit-transform:rotate(328deg); transform:rotate(328deg);}
      .fa-rotate-329{-ms-transform:rotate(329deg); -webkit-transform:rotate(329deg); transform:rotate(329deg);}
      .fa-rotate-330{-ms-transform:rotate(330deg); -webkit-transform:rotate(330deg); transform:rotate(330deg);}
      .fa-rotate-331{-ms-transform:rotate(331deg); -webkit-transform:rotate(331deg); transform:rotate(331deg);}
      .fa-rotate-332{-ms-transform:rotate(332deg); -webkit-transform:rotate(332deg); transform:rotate(332deg);}
      .fa-rotate-333{-ms-transform:rotate(333deg); -webkit-transform:rotate(333deg); transform:rotate(333deg);}
      .fa-rotate-334{-ms-transform:rotate(334deg); -webkit-transform:rotate(334deg); transform:rotate(334deg);}
      .fa-rotate-335{-ms-transform:rotate(335deg); -webkit-transform:rotate(335deg); transform:rotate(335deg);}
      .fa-rotate-336{-ms-transform:rotate(336deg); -webkit-transform:rotate(336deg); transform:rotate(336deg);}
      .fa-rotate-337{-ms-transform:rotate(337deg); -webkit-transform:rotate(337deg); transform:rotate(337deg);}
      .fa-rotate-338{-ms-transform:rotate(338deg); -webkit-transform:rotate(338deg); transform:rotate(338deg);}
      .fa-rotate-339{-ms-transform:rotate(339deg); -webkit-transform:rotate(339deg); transform:rotate(339deg);}
      .fa-rotate-340{-ms-transform:rotate(340deg); -webkit-transform:rotate(340deg); transform:rotate(340deg);}
      .fa-rotate-341{-ms-transform:rotate(341deg); -webkit-transform:rotate(341deg); transform:rotate(341deg);}
      .fa-rotate-342{-ms-transform:rotate(342deg); -webkit-transform:rotate(342deg); transform:rotate(342deg);}
      .fa-rotate-343{-ms-transform:rotate(343deg); -webkit-transform:rotate(343deg); transform:rotate(343deg);}
      .fa-rotate-344{-ms-transform:rotate(344deg); -webkit-transform:rotate(344deg); transform:rotate(344deg);}
      .fa-rotate-345{-ms-transform:rotate(345deg); -webkit-transform:rotate(345deg); transform:rotate(345deg);}
      .fa-rotate-346{-ms-transform:rotate(346deg); -webkit-transform:rotate(346deg); transform:rotate(346deg);}
      .fa-rotate-347{-ms-transform:rotate(347deg); -webkit-transform:rotate(347deg); transform:rotate(347deg);}
      .fa-rotate-348{-ms-transform:rotate(348deg); -webkit-transform:rotate(348deg); transform:rotate(348deg);}
      .fa-rotate-349{-ms-transform:rotate(349deg); -webkit-transform:rotate(349deg); transform:rotate(349deg);}
      .fa-rotate-350{-ms-transform:rotate(350deg); -webkit-transform:rotate(350deg); transform:rotate(350deg);}
      .fa-rotate-351{-ms-transform:rotate(351deg); -webkit-transform:rotate(351deg); transform:rotate(351deg);}
      .fa-rotate-352{-ms-transform:rotate(352deg); -webkit-transform:rotate(352deg); transform:rotate(352deg);}
      .fa-rotate-353{-ms-transform:rotate(353deg); -webkit-transform:rotate(353deg); transform:rotate(353deg);}
      .fa-rotate-354{-ms-transform:rotate(354deg); -webkit-transform:rotate(354deg); transform:rotate(354deg);}
      .fa-rotate-355{-ms-transform:rotate(355deg); -webkit-transform:rotate(355deg); transform:rotate(355deg);}
      .fa-rotate-356{-ms-transform:rotate(356deg); -webkit-transform:rotate(356deg); transform:rotate(356deg);}
      .fa-rotate-357{-ms-transform:rotate(357deg); -webkit-transform:rotate(357deg); transform:rotate(357deg);}
      .fa-rotate-358{-ms-transform:rotate(358deg); -webkit-transform:rotate(358deg); transform:rotate(358deg);}
      .fa-rotate-359{-ms-transform:rotate(359deg); -webkit-transform:rotate(359deg); transform:rotate(359deg);}
      .fa-rotate-360{-ms-transform:rotate(360deg); -webkit-transform:rotate(360deg); transform:rotate(360deg);}